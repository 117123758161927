
import comp from "@/components/base"
import { mapActions } from "vuex"
import icons from "../../assets/icons"
import { computed, defineComponent } from "@vue/runtime-core"
import { useStore } from "@/store/store"

export default defineComponent({
  name: "RecipeTable",
  components: {
    ...comp,
    ...icons,
  },
  setup() {
    return {
      popRecipes: computed(() => useStore().getters.popRecipes),
    }
  },
  computed: {},
  methods: {
    ...mapActions(["openDeleteRecipe", "openRecipe"]),
  },
})
