<template>
  <Table class="table-hover">
    <template v-slot:headRow>
      <TH>Edit</TH>
      <TH>Name</TH>
      <TH>Style</TH>
      <TH>Created</TH>
      <TH>Edited</TH>
    </template>
    <template v-slot:body>
      <TR v-for="(item, index) in popRecipes" :key="index">
        <TD>
          <ButtonGroup>
            <ButtonLink name="RecipeSheet" :params="item.id">
              <BIconPencilSquare />
            </ButtonLink>
            <Button @click="openDeleteRecipe(item.id)">
              <BIconTrash />
            </Button>
          </ButtonGroup>
        </TD>
        <TD>{{ item.name }}</TD>
        <TD> {{ item.styleName }}</TD>
        <TD> {{ item.createdText }}</TD>
        <TD> {{ item.updatedText }}</TD>
      </TR>
    </template>
  </Table>
</template>

<script lang="ts">
import comp from "@/components/base"
import { mapActions } from "vuex"
import icons from "../../assets/icons"
import { computed, defineComponent } from "@vue/runtime-core"
import { useStore } from "@/store/store"

export default defineComponent({
  name: "RecipeTable",
  components: {
    ...comp,
    ...icons,
  },
  setup() {
    return {
      popRecipes: computed(() => useStore().getters.popRecipes),
    }
  },
  computed: {},
  methods: {
    ...mapActions(["openDeleteRecipe", "openRecipe"]),
  },
})
</script>
