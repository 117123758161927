<template>
  <Container>
    <Header>
      Recipes
      <template #right>
        <BadgeBtn @click="openRecipe()">
          + recipe
        </BadgeBtn>
      </template>
    </Header>
    <RecipeTable />
  </Container>
</template>

<script>
import RecipeTable from "../components/tabels/RecipeTable.vue"

import comp from "../components/base"
import { mapActions } from "vuex"
export default {
  name: "Recipes",
  components: {
    ...comp,
    RecipeTable,
  },
  methods: {
    ...mapActions(["openRecipe"]),
  },
}
</script>
